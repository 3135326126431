.content {
  display: flex;
  flex-direction: row;
  .preview-header {
    padding: 2% 1%;
  }
  .preview-outer-container {
    display: flex;
    // justify-content: space-around;
    .mobile-width{
      flex: 0 0 35% !important;
      padding: 2% 1% !important;
    }
    .preview-container {
      flex: 1 1 100%;
      height: 100vh;
      overflow-y: scroll;
      
      /* max-width: 70%; */
      padding: 2% 10%;
      .rjsfform {
        width: 35vw;
        .ant-collapse-header{
          padding: 4px 0px;
        }
        .ant-collapse-arrow{
          top: 30%;
        }
      }
    }
  }

  .sidebarOverlap {
    width: 100%;
  }
  .ant-tabs-tabpane-active {
    width: 100%;

    display: flex;
    flex-direction: column;
    .submit-btn {
      align-self: center;
      margin-bottom: 100px;
    }
  }
}
