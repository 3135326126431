.sidebar {
  position: sticky;
  width: 25% !important;
  height: 100%;
  left: 0;
  top: 0;
  // background: #0068a9;
  min-height: 100vh;
  .ant-layout-sider-children{
    
  }
}