.login-form {
  width: 300px;
}
.login-form-forgot {
  float: right;
  padding: 0px;
}
.login-form-button {
  width: 100%;
}
