.content {
  display: flex;
  flex-direction: row;
  .home-header {
    padding: 2% 1%;
  }
  .home-outer-container {
    display: flex;
    .home-container {
      /* max-width: 70%; */
      padding: 2% 1%;
      .rjsfform {
        width: 25vw;
        .ant-collapse-header{
          padding: 4px 0px;
        }
        .ant-collapse-arrow{
          top: 30%;
        }
        .ant-card-body{
          padding: .5rem;
        }
      }
    }
  }

  .sidebarOverlap {
    width: 100%;
  }
  .ant-tabs-tabpane-active {
    width: 100%;

    display: flex;
    flex-direction: column;
    .submit-btn {
      align-self: center;
      margin-bottom: 100px;
    }
  }
}
